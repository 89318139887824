import classNames from 'classnames'
import _ from 'lodash'
import { createForm } from 'rc-form'
import React, { useCallback, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { fetchSelf, login } from '../actions/userActions'
import { formatFormNotifications } from '../utils/forms'
import { AuthView } from '../components/AuthView'
import { Button } from '../components/Button'
import { Card, CardFooter, CardHeading, CardMain } from '../components/Card'
import { Form, FormError, FormInput, FormItem, FormLabel, FormNotifications } from '../components/Form'
import { Link } from '../components/Link'
import { Translation } from '../components/Translation'

const LoginView = ({ dispatch, form, form: { getFieldError, validateFields }, isAuthenticated, translate }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = useCallback(
        event => {
            event.preventDefault()

            if (isLoading || isAuthenticated) {
                return false
            }

            validateFields(async (formErrors, formValues) => {
                if (!formErrors) {
                    setErrors([])
                    setIsLoading(true)
                    dispatch(login(formValues))
                        .then(response => {
                            // Leave isLoading to true in order to indicate redirection
                            setErrors([])
                            // Load the authenticated user's details.
                            // Redirection on success is managed through "redux-auth-wrapper".
                            dispatch(fetchSelf())
                        })
                        .catch(error => {
                            setErrors([{ message: translate('loginForm.error') }])
                            setIsLoading(false)
                        })
                }
            })

            return false
        },
        [dispatch, isAuthenticated, isLoading, setErrors, setIsLoading, translate, validateFields]
    )

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors
        ? formatFormNotifications(errors)
        : isAuthenticated
        ? [
              {
                  message: translate('loginForm.confirmation'),
              },
          ]
        : []
    const hasNotifications = notifications.length > 0

    return (
        <AuthView>
            <Card option={['auth']}>
                <CardHeading option={['largePadding']}>
                    <Translation value="loginForm.heading" />
                </CardHeading>
                <CardMain>
                    {hasNotifications && (
                        <FormItem>
                            <FormNotifications
                                hasErrors={hasErrors}
                                hasSuccess={isAuthenticated}
                                items={notifications}
                            />
                        </FormItem>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <FormItem className={classNames({ 'has-error': !!getFieldError('email') })}>
                            <FormLabel inputId="email" option={['small']}>
                                <Translation value="form.email" />
                            </FormLabel>
                            <FormInput
                                form={form}
                                id="email"
                                name="email"
                                type="email"
                                fieldOptions={{
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: translate('form.emailMissing'),
                                        },
                                    ],
                                }}
                                option={['small', 'blue-light']}
                            />
                            <FormError>{getFieldError('email')}</FormError>
                        </FormItem>
                        <FormItem className={classNames({ 'has-error': !!getFieldError('password') })}>
                            <FormLabel inputId="password" option={['small']}>
                                <Translation value="form.password" />
                            </FormLabel>
                            <FormInput
                                form={form}
                                id="password"
                                name="password"
                                type="password"
                                fieldOptions={{
                                    initialValue: '',
                                    rules: [
                                        {
                                            required: true,
                                            message: translate('form.passwordMissing'),
                                        },
                                    ],
                                }}
                                option={['small', 'blue-light']}
                            />
                            <FormError>{getFieldError('password')}</FormError>
                        </FormItem>
                        <Button
                            className={classNames({ 'is-loading': isLoading })}
                            hasWrapper
                            option={['blue', 'block', 'spinner']}
                            type="submit"
                        >
                            <Translation value="loginForm.submit" />
                        </Button>
                    </Form>
                </CardMain>
                <CardFooter>
                    <Link option={['blue']} route="login-reset-password">
                        <Translation value="loginForm.lostPassword" />
                    </Link>
                </CardFooter>
            </Card>
        </AuthView>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})

export default withLocalize(createForm()(connect(mapStateToProps)(LoginView)))
