import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import React, { useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Layout, LayoutItem } from '../../components/Layout'
import { NavActions } from '../../components/NavActions'
import { Translation } from '../../components/Translation'

import {
    clearProjectsCache,
    updateProjectAddSelf,
    updateProjectRemoveSelf,
    refreshProjects,
} from '../../actions/projectActions'
import { clearSelfCache, createFavoriteProject, fetchSelf, removeFavoriteProject } from '../../actions/userActions'
import { formatFormNotifications, getUserFavoriteProjects, renderRoute } from '../../utils'

import '../../components/ProjectCards/_projectCards.scss'

const ProjectCardsItem = ({
    dispatch,
    project = {},
    showAdd = false,
    showEdit = false,
    showFavoriteProjects = false,
    showRemove = false,
    translate,
    user,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const isFavoriteProject = _.includes(getUserFavoriteProjects(user), project.id)

    const ItemContainer = ({ children, ...props }) => {
        if (showAdd === true) {
            return <div {...props}>{children}</div>
        } else {
            return (
                <RouterLink
                    className={classNames(props.className, '-link')}
                    to={renderRoute({
                        id: 'project-details',
                        replacements: { projectId: project.id },
                    })}
                >
                    {children}
                </RouterLink>
            )
        }
    }

    const handleClickAddFavoriteProject = () => {
        if (!isLoading) {
            setIsLoading(true)
            createFavoriteProject({ projectId: project.id })
                .then(response => {
                    clearSelfCache()
                    dispatch(fetchSelf())
                    setIsLoading(false)
                    toast.success(translate('projectList.successAddFavoriteProject'))
                })
                .catch(error => {
                    formatFormNotifications(error.response.data).forEach(element => {
                        toast.error(translate('common.errorPrompt') + element.message)
                    })
                    setIsLoading(false)
                })
        }
    }

    const handleClickRemoveFavoriteProject = () => {
        if (!isLoading) {
            setIsLoading(true)
            removeFavoriteProject(project.id)
                .then(response => {
                    clearSelfCache()
                    dispatch(fetchSelf())
                    setIsLoading(false)
                    toast.success(translate('projectList.successRemoveFavoriteProject'))
                })
                .catch(error => {
                    formatFormNotifications(error.response.data).forEach(element => {
                        toast.error(translate('common.errorPrompt') + element.message)
                    })
                    setIsLoading(false)
                })
        }
    }

    const handleClickAddSelfToProject = () => {
        if (!isLoading) {
            setIsLoading(true)
            updateProjectAddSelf(project.id)
                .then(response => {
                    clearProjectsCache()
                    dispatch(refreshProjects())
                    setIsLoading(false)
                    toast.success(translate('projectList.successAddSelfToProject'))
                })
                .catch(error => {
                    formatFormNotifications(error.response.data).forEach(element => {
                        toast.error(translate('common.errorPrompt') + element.message)
                    })
                    setIsLoading(false)
                })
        }
    }

    const handleClickRemoveSelfFromProject = () => {
        if (!isLoading) {
            setIsLoading(true)
            updateProjectRemoveSelf(project.id)
                .then(response => {
                    if (isFavoriteProject) {
                        clearSelfCache()
                        dispatch(fetchSelf())
                    }
                    clearProjectsCache()
                    dispatch(refreshProjects())
                    setIsLoading(false)
                    toast.success(translate('projectList.successRemoveSelfFromProject'))
                })
                .catch(error => {
                    formatFormNotifications(error.response.data).forEach(element => {
                        toast.error(translate('common.errorPrompt') + element.message)
                    })
                    setIsLoading(false)
                })
        }
    }

    let navActions = []
    if (showFavoriteProjects && !isFavoriteProject) {
        navActions.push({
            label: 'projectList.addFavoriteProject',
            onClick: handleClickAddFavoriteProject,
        })
    }
    if (showFavoriteProjects && isFavoriteProject) {
        navActions.push({
            label: 'projectList.removeFavoriteProject',
            onClick: handleClickRemoveFavoriteProject,
        })
    }
    if (showEdit) {
        navActions.push({
            label: 'projectList.editProject',
            route: { id: 'project-edit', replacements: { projectId: project.id } },
        })
    }
    if (showAdd) {
        navActions.push({
            label: 'projectList.addSelfToProject',
            onClick: handleClickAddSelfToProject,
        })
    }
    if (showRemove) {
        navActions.push({
            label: 'projectList.removeSelfFromProject',
            onClick: handleClickRemoveSelfFromProject,
        })
    }

    return (
        <li key={project.id} className="projectCards_item">
            <NavActions className="projectCards_buttons" option={['dark', 'right']} items={navActions} />
            <ItemContainer className="projectCards_container">
                <h2 className="projectCards_title">
                    <Translation value={project.name} />
                </h2>
                {isFavoriteProject && (
                    <div className="projectCards_favorite">
                        <span className="projectCards_favorite_inner">
                            <Translation value="common.favorite" />
                        </span>
                    </div>
                )}
                <div className="projectCards_footer">
                    <Layout>
                        {!_.isEmpty(project.launchDate) && (
                            <LayoutItem className="projectCards_footer_column" utility={['1/2']}>
                                <h3 className="projectCards_footer_label">
                                    <Translation value="projectDetails.launch" />
                                </h3>
                                <p className="projectCards_footer_value">
                                    {moment(project.launchDate).format('YYYY-MM-DD')}
                                </p>
                            </LayoutItem>
                        )}
                        {!_.isEmpty(project.currentMilestone) && (
                            <LayoutItem className="projectCards_footer_column" utility={['1/2']}>
                                <h3 className="projectCards_footer_label">
                                    <Translation value="projectList.ongoingMilestone" />
                                </h3>
                                <p className="projectCards_footer_value">
                                    <Translation value={project.currentMilestone.name} />
                                </p>
                            </LayoutItem>
                        )}
                    </Layout>
                </div>
            </ItemContainer>
        </li>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default withLocalize(connect(mapStateToProps)(ProjectCardsItem))
