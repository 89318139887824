import React from 'react'
import { connect } from 'react-redux'

import { userCanDo } from '../utils'
import { AuthView } from '../components/AuthView'
import { ButtonCircle } from '../components/Button'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { Layout, LayoutItem } from '../components/Layout'
import { ProjectListContainer } from '../components/Project'
import { ProjectCards } from '../components/ProjectCards'
import { Section } from '../components/Section'
import { TabPanel, TabSelector, useTabs } from '../components/Tabs'
import { Translation } from '../components/Translation'

import '../components/Project/_projectList.scss'

const ProjectListView = ({ user }) => {
    const userCanUpdateProjects = userCanDo(user, 'project.update')
    const userCanFavoriteProjects = userCanDo(user, 'project.favoriteProjects')

    const [selectedTab, setSelectedTab] = useTabs(['incomplete', 'completed', 'remaining'], 'incomplete')

    const incompleteProjects = list => {
        if (user.isEmployee) {
            return list.filter(item => !item.isCompleted)
        } else {
            return list.filter(item => !item.isCompleted && item.clients.includes(user.id))
        }
    }

    const completedProjects = list => {
        if (user.isEmployee) {
            return list.filter(item => item.isCompleted)
        } else {
            return list.filter(item => item.isCompleted && item.clients.includes(user.id))
        }
    }

    return (
        <AuthView option={['landing', 'projectList']}>
            <Layout option={['center']}>
                <LayoutItem option={['contentSmall']}>
                    <Container>
                        <Section>
                            <ProjectListContainer
                                render={({ projects }) => (
                                    <div className="projectList u-anim -delay1">
                                        <Heading className="projectList_heading" el="h1" option={['blue', 'medium']}>
                                            <Translation value="common.projects" />
                                        </Heading>
                                        <div className="projectList_filters">
                                            <TabSelector
                                                className="projectList_filters_button"
                                                isActive={selectedTab === 'incomplete'}
                                                onClick={() => setSelectedTab('incomplete')}
                                            >
                                                <Heading className="projectList_filters_label" el="h2">
                                                    <Translation value="projectList.filters.incomplete" />
                                                    <span className="projectList_filters_indicator">
                                                        {incompleteProjects(projects).length}
                                                    </span>
                                                </Heading>
                                            </TabSelector>
                                            <TabSelector
                                                className="projectList_filters_button"
                                                isActive={selectedTab === 'completed'}
                                                onClick={() => setSelectedTab('completed')}
                                            >
                                                <Heading className="projectList_filters_label" el="h2">
                                                    <Translation value="projectList.filters.completed" />
                                                    <span className="projectList_filters_indicator">
                                                        {completedProjects(projects).length}
                                                    </span>
                                                </Heading>
                                            </TabSelector>
                                        </div>
                                        {projects.length > 1 ? (
                                            <>
                                                <TabPanel isHidden={selectedTab !== 'incomplete'}>
                                                    <ProjectCards
                                                        projects={incompleteProjects(projects)}
                                                        showEdit={userCanUpdateProjects}
                                                        showFavoriteProjects={userCanFavoriteProjects}
                                                        showRemove={false}
                                                        user={user}
                                                    />
                                                </TabPanel>
                                                <TabPanel isHidden={selectedTab !== 'completed'}>
                                                    <ProjectCards
                                                        projects={completedProjects(projects)}
                                                        showEdit={userCanUpdateProjects}
                                                        showRemove={false}
                                                    />
                                                </TabPanel>
                                            </>
                                        ) : (
                                            <div className="projectList_list">
                                                <div className="projectList_item">
                                                    <Translation value="projectList.noProjects" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </Section>
                    </Container>
                </LayoutItem>
            </Layout>
            {userCanDo(user, 'project.create') && (
                <ButtonCircle
                    className="projectList_create"
                    route="project-create"
                    option={['medium', 'delay2']}
                    utility={['anim']}
                    icon="plus"
                />
            )}
        </AuthView>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ProjectListView)
